<template>
  <div class="main">
    <h3>Продажи</h3>
    <div class="sales">
      <div
          class="sale__item"
          v-for="(product, index) in products"
          :key="index"
      >

        <div class="sale__header">
          <p>💳 <b>Тип оплаты:</b> {{ product.payment_type }}</p>
          <p>💰 <b>Сумма оплаты:</b> {{ formatAmount(product.total) }} тнг</p>
          <p>📅 <b>Дата продажи:</b> {{ formatDate(product.created_at) }}</p>
        </div>

        <div
            class="sale__order"
            v-for="(order, orderIndex) in product.order_item"
            :key="orderIndex"
        >
          <p>🛒 <b>Продано:</b> {{ order.quantity }} шт</p>
          <p>📦 <b>Название:</b> {{ order.product.name }}</p>
          <p>💵 <b>Цена:</b> {{ formatAmount(order.product.price) }} тнг</p>
          <p>🏚️ <b>Остаток:</b> {{ order.product.quantity }} шт</p>
        </div>
      </div>

      <div v-if="pageCount" class="sale__pagination">
        <v-pagination
            v-model="page"
            :length="pageCount"
            :total-visible="3"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from "lodash";

export default {
  name: "Mailing",
  data() {
    return {
      pageCount: 0,
      page: 1,
      products: [],
    };
  },
  methods: {
    formatAmount(amount) {
      if (isNaN(amount)) return "Invalid number";
      return new Intl.NumberFormat("ru-RU", {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(amount);
    },
    formatDate(dateString) {
      const options = { year: "numeric", month: "long", day: "numeric" };
      return new Date(dateString).toLocaleDateString("ru-RU", options);
    },
    debounceSearchProduct: debounce(function () {
      this.fetchProducts();
    }, 1000),
    searchProduct() {
      this.debounceSearchProduct();
    },
    fetchProducts(page = 1) {
      this.$axios({
        method: "get",
        url: `${this.$API_URL}good_sales?page=${page}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
          .then((res) => {
            this.products = res.data.data || [];
            this.pageCount = res.data.last_page || 1;
          })
          .catch((error) => {
            console.error("Error fetching products:", error);
          });
    },
  },
  mounted() {
    this.fetchProducts();
  },
  watch: {
    page(newPage) {
      this.fetchProducts(newPage);
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  padding: 20px;
}

.sales {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.sale__item {
  background: white;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #ddd;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.sale__header p {
  margin: 5px 0;
}

.sale__order {
  background: #f9f9f9;
  padding: 10px;
  margin-top: 15px;
  border-radius: 8px;
}

.sale__pagination {
  align-self: center;
  margin-top: 20px;
}
</style>
